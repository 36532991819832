<template>
  <v-container fluid>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="t in teams"
        :key="t.team._id"
      >
        <v-expansion-panel-header>
          {{ t.team.name }}: {{ mannschaftgesamtergebnis(e, r, t.team._id) | float2_0 }} Punkte
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            v-for="g in d"
            :key="`${t.team._id}-${g._discipline}`"
          >
            <v-card-title class="headline">
              {{ g.name }}: {{ mannschaftgeraetergebnis(e, r, t.team._id, g._discipline) | float2_0 }} Punkte
            </v-card-title>
            <v-card-text>
              <v-data-table
                :items="turner(t.team._id, g._discipline)"
                :headers="[
                  { text: 'Nr.', value: 'order', sortable: false, align: 'center' },
                  { text: 'Turner', value: 'person', sortable: false },
                  { text: 'Teile', value: 'parts', sortable: false, align: 'center' },
                  { text: 'D-Note', value: 'dscore', sortable: false, align: 'center' },
                  { text: 'Abzug', value: 'deduction', sortable: false, align: 'center' },
                  { text: 'Endnote', value: 'final', sortable: false, align: 'center' }
                ]"
                hide-default-footer
                :items-per-page="-1"
                :mobile-breakpoint="0"
              >
                <template #item.person="{item}">
                  {{ item.person | person }}
                </template>
                <template #item.dscore="{item}">
                  {{ item.result.dscore | float1_0 }}
                </template>
                <template #item.deduction="{item}">
                  {{ item.result.deduction | float2_0 }}
                </template>
                <template #item.final="{item}">
                  <span style="font-weight:bold;font-size:120%;">{{ item.result.final | float2_0 }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card v-if="topathletes.length > 0" flat>
      <h2>Beste Sechskämpfer</h2>
      <table style="font-size:100%;">
        <tr class="head">
          <td>Turner</td>
          <td>Mannschaft</td>
          <td>Punkte</td>
        </tr>
        <tr
          v-for="(a, i) in topathletes"
          :key="a.person._id"
          :class="{even: i % 2 === 0}"
        >
          <td>{{ a.person | person }}</td>
          <td>{{ a.team.name }}</td>
          <td>{{ a.final | float2_0 }}</td>
        </tr>
      </table>
    </v-card>
    <v-btn block @click="ergebnis(e, df, r)" color="primary" class="mt-8">PDF-Download</v-btn>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'
import { usePdf } from '@/views/components/stb_m_2021/plugins/pdf'

export default {
  name: 'wk_4aus5',

  setup (props, context) {
    return {
      ...useCalc(props, context),
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    d () {
      return this.geraete(this.e, this.df)
    },
    h () {
      return this.heim(this.e)
    },
    g () {
      return this.gast(this.e)
    },
    teams () {
      return this.e.teams.map(t => ({
        ...t,
        final: this.mannschaftgesamtergebnis(this.e, this.r, t.team._id)
      })).sort((a, b) => a.final > b.final ? -1 : 1)
    },
    topathletes () {
      return this.e?.topathletes?.filter(a => a.disciplines === 6)?.sort((a, b) => a.final < b.final ? 1 : -1) || []
    }
  },

  methods: {
    turner (tid, did) {
      return this.mannschaftturnerangeraet(this.e, this.r, tid, did)
    }
  }
}
</script>

<style scoped>

</style>
